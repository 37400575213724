import React from 'react'
import './manage-page.css'
import { navigate } from '@reach/router'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

//Parse
import { GetTicketStats, GetPump } from '../../services/parse-bridge'

//NPM
import { Table, Button, ButtonGroup } from 'reactstrap'
import styled from 'styled-components'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

//Components
import ManagedPumpContainer from '../../components/manage-pump-container'
import SoldByType from '../../components/sold-by-type'
import { Link } from 'gatsby'

var pump = undefined

const AnalyticsTitle = styled.h1`
  /* font-family: 'Avenir-Black'; */
  font-size: 32px;
  line-height: 38px;
  margin: 50px 0;
`

const Separator = styled.div`
  width: 70px;
  height: 3px;
  background-color: #000;
  opacity: 0.1;
  display: block;
  margin: 30px auto;
`

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 10px auto;
`

const Action = styled.button`
  color: #3659e3;
  background: none;
  flex: 1;
  font-size: 0.7666666667rem;
  font-weight: 500;
  line-height: 1.0666666667rem;
  font-family: 'Poppins';
  border: none;
  padding: 0;
  line-height: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`

const TableContainer = styled.div`
  overflow-x: auto;
`

const THeading = styled.th`
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 15px;
  color: #0b1c44;
`

const TDetails = styled.td`
  font-family: 'Poppins';
  font-size: 14px;
  color: #39364f;
`

const TicketChartContainer = styled.div``
const SectionTitle = styled.h2`
  font-size: 24px;
`

class ManagePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pumpId: '',
      pumpName: '',
      stats: undefined,
      raw: undefined,
      percent: '',
      pump: null,
    }
  }

  componentWillMount() {
    let searchId = String(this.props.location.search)
    let id = searchId.substr(1, 10)
    let name = searchId.substr(12)
    console.log(name)
    let decodedName = decodeURI(name)
    console.log(id)
    this.setState({ pumpId: id, pumpName: decodedName }, () => {
      GetTicketStats(id).then(results => {
        console.log('got stats func')
        this.setState({ stats: results }, () => this.additionalSetup())
      })
    })
  }

  additionalSetup() {
    let stats = this.state.stats
    let raw = stats.pop()
    let percent = `${(raw.ticketsSold / raw.totalBatchSize) * 100}%`

    let pump = GetPump(this.state.pumpId).then(pump => {
      console.log(pump)
      this.setState({ raw: raw, percent: percent, pump: pump }, () => {
        console.log(this.state)
      })
    })
  }

  goToCreate(pumpId) {
    console.log('goToCreate: @param', pumpId)
    navigate(`/dashboard/create-tickets?=${this.state.pumpId}`, {
      state: { pump: this.state.pump },
    })
  }

  goToIssue(pumpId) {
    console.log('goToIssue: @param', pumpId)
    navigate(`/dashboard/issue-tickets?=${this.state.pumpId}`, {
      state: { pump: this.state.pump },
    })
  }

  render() {
    return (
      <Layout>
        <div className="TicketStats">
          {this.state.pump ? (
            <ManagedPumpContainer
              name={this.state.pump.name}
              flyer={this.state.pump.flyer}
            />
          ) : null}

          <ActionGroup>
            <Action onClick={() => this.goToCreate(this.state.pumpId)}>
              CREATE TICKETS
            </Action>
            <Action onClick={() => this.goToIssue(this.state.pumpId)}>
              ISSUE TICKETS
            </Action>
            <Action>DELETE PUMP</Action>
          </ActionGroup>

          {this.state.pump && this.state.pump.hasTickets ? (
            <>
              <AnalyticsTitle>Welcome to your event analytics!</AnalyticsTitle>
              <Separator />
              <SectionTitle>Quick Overview</SectionTitle>
              <div className="card ticketsSold">
                <p>
                  {this.state.raw.ticketsSold} sold /{' '}
                  {this.state.raw.totalBatchSize} tickets
                </p>
                <div className="progress-bar">
                  <span className="bar">
                    <span
                      style={{ width: this.state.percent }}
                      className="progress"
                    />
                  </span>
                </div>
                <div className="byType">
                  {this.state.stats.map(stat => (
                    <SoldByType
                      ticketType={stat.ticketType}
                      ticketsSold={stat.ticketsSold}
                      batchSize={stat.batchSize}
                    />
                  ))}
                </div>
              </div>
              <div className="card revenue">
                <p>Total Revenue</p>
                <h2>${(this.state.raw.netSales / 100).toFixed(2)} USD</h2>
              </div>
              <div className="card views">
                <p>Event Views</p>
                <h2>{this.state.pump ? this.state.pump.views : 0}</h2>
              </div>
              <Separator />
              <div className="typeBreakdown">
                <h2>Sales by Ticket Type</h2>
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <THeading>Ticket Type</THeading>
                        <THeading>Price</THeading>
                        <THeading>Sold</THeading>
                        <THeading>Revenue</THeading>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.stats.map(stat => (
                        <tr>
                          <TDetails>{stat.ticketType}</TDetails>
                          <TDetails>
                            ${(stat.ticketPrice / 100).toFixed(2)}
                          </TDetails>
                          <TDetails>
                            {stat.ticketsSold}/{stat.batchSize}
                          </TDetails>
                          <TDetails>
                            $
                            {(
                              (stat.ticketsSold * stat.ticketPrice) /
                              100
                            ).toFixed(2)}
                          </TDetails>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </div>
              <Separator />
              <TicketChartContainer>
                <SectionTitle>Sales this week</SectionTitle>
                <ResponsiveContainer width={'100%'} height={300}>
                  <LineChart
                    width={600}
                    height={300}
                    data={data}
                    margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid opacity={0.5} strokeDasharray="4 4" />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="ticketsSold"
                      stroke="#fc444e"
                      activeDot={{ r: 8 }}
                    />
                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                  </LineChart>
                </ResponsiveContainer>
              </TicketChartContainer>

              {/* <ResponsiveContainer width={'100%'} height={300}>
                            <BarChart width={730} height={250} data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="ticketsSold" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer> */}

              <Separator />
              <div className="shareEvent">
                <p>Your event url is:</p>
                {this.state.pump ? (
                  <pre>
                    <a
                      target="_blank"
                      href={`https://www.wheredpump.com/events/${
                        this.state.pump.id
                      }`}
                    >
                      https://www.wheredpump.com/events/{this.state.pump.id}
                    </a>
                  </pre>
                ) : null}
              </div>
            </>
          ) : (
            <div className="ticketCallout">
              <h1>Get Started with Digital Tickets for FREE</h1>
              <p>
                Tap 'Create Tickets' to create tickets for your event. Once
                tickets are set up and verified, your stats on sales & revenue
                will appear here.
              </p>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

const data = [
  { name: 'Mon 1st', ticketsSold: 20 },
  { name: 'Tue 2nd', ticketsSold: 24 },
  { name: 'Wed 3rd', ticketsSold: 54 },
  { name: 'Thu 4th', ticketsSold: 0 },
  { name: 'Fri 5th', ticketsSold: 154 },
  { name: 'Sat 6th', ticketsSold: 12 },
  { name: 'Sun 7th', ticketsSold: 240 },
]

export default ManagePage
