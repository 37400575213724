import React, { Component } from 'react';
import './sold-by-type.css'

class SoldByType extends Component {
    render() { 
        return (
            <div className="SoldByType">
                {this.props.ticketType}: {this.props.ticketsSold}/{this.props.batchSize}
            </div>
        );
    }
}
 
export default SoldByType;